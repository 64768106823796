import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import SiteHeader from "./SiteHeader";
import HomeSection from "./HomeSection";
import ServicesSection from "./ServicesSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import EnrollPopUp from "./EnrollPopUp";
import SiteFooter from "./SiteFooter";
import "./styles/global/layout.scss";
import "./styles/global/typography.scss";
import "./styles/global/media.scss";
import "./styles/global/forms.scss";
import "./styles/global/lists.scss";
import "./styles/global/tables.scss";
import "./styles/global/buttons.scss";
import "./styles/index.scss";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPopUpOpen: false }
  }

  handleEnrollClick = () => {
    document.getElementById("root").classList.add("enroll-pop-up--is-open");
    this.setState({
      isPopUpOpen: true,
    });
  }

  handlePopUpClose = () => {
    document.getElementById("root").classList.remove("enroll-pop-up--is-open");
    this.setState({
      isPopUpOpen: false,
    });
  }

  render() {
    return (
      <div className="index" itemScope itemType="http://schema.org/LocalBusiness">
        <SiteHeader
          handleEnrollClick={this.handleEnrollClick.bind(this)}
          popUpState={this.state.isPopUpOpen ? "true" : "false"} />
        <main>
          <HomeSection handleEnrollClick={this.handleEnrollClick.bind(this)} />
          <ServicesSection />
          <AboutSection />
          <ContactSection />
          <EnrollPopUp handlePopUpClose={this.handlePopUpClose.bind(this)} />
        </main>
        <SiteFooter />
      </div>
    );
  }
};

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
