import * as React from "react";
import priorityHealthRxLogo from "./images/priority-health-rx-logo.png";
import stethascopeImage from "./images/stethascope.jpg";
import FullWidthBanner from "./FullWidthBanner";
import HeaderOverBanner from "./HeaderOverBanner";
import ContentOverBanner from "./ContentOverBanner";
import "./styles/HomeSection.scss";

const EnrollmentForm = () => <section className="enrollment-form"></section>;

class HomeSection extends React.Component {
  render() {
    return (
      <section className="home-section">
        <FullWidthBanner color="blue" backgroundImage={stethascopeImage} id="home" />
        <HeaderOverBanner>
          <img src={priorityHealthRxLogo} className="header-over-banner__logo" alt="" />
        </HeaderOverBanner>
        <ContentOverBanner>
          <h3 className="content-over-banner__title content-over-banner__title--no-margin" itemProp="name">Priority Health Rx</h3><h4 className="content-over-banner__small-title">is a full service pharmacy</h4>
          <p>We are dedicated to making prescription therapy treatment convenient for our patients and clients alike.</p>
          <p>We utilize the most advanced technologies in the marketplace to ensure patients and caregivers have the tools necessary to adhere to their drug regimen.</p>
          <p>Beyond the full scope of retail pharmacy Priority Health Rx has extensive experience working with various disease states, such as: Chronic Kidney Disease, HIV, RA.</p>
          <p itemProp="description">At Priority Health Rx we believe that getting the therapy you need should be easy, not complicated. Let our pharmacy come to you!</p>
          <div className="content-over-banner__button-container">
            <button onClick={this.props.handleEnrollClick}>Enroll Now</button>
          </div>
        </ContentOverBanner>
        <EnrollmentForm />
      </section>
    );
  }
}

export default HomeSection;
