import * as React from "react";
import laptopImage from "./images/laptop.jpg";
import FullWidthBanner from "./FullWidthBanner";
import HeaderOverBanner from "./HeaderOverBanner";
import ContentOverBanner from "./ContentOverBanner";
import "./styles/ServicesSection.scss";

class ServicesSection extends React.Component {
  render() {
    return (
      <section className="services-section">
        <FullWidthBanner color="green" backgroundImage={laptopImage} id="services" />
        <HeaderOverBanner>
          <h3 className="header-over-banner__title">Our pharmacists use the most advanced technology in the industry</h3>
        </HeaderOverBanner>
        <ContentOverBanner>
          <h2 className="content-over-banner__title">Services</h2>
          <h4>Home and Clinic Delivery</h4>
          <p>We customize a program that meets each patient’s unique needs for delivery and payment options</p>
          <h4>Extensive Drug Utilization Review</h4>
          <p>Our pharmacists use the most advanced technology in the industry to ensure all medications are appropriate for each patient and to avoid conflicts in medications</p>
          <h4>Pharmacist Consultation</h4>
          <p>Our pharmacists are available for any questions or concerns about medications prescribed</p>
        </ContentOverBanner>
      </section>
    );
  }
}

export default ServicesSection;
