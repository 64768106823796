import * as React from "react";
import manWithPhoneImage from "./images/man-with-phone.jpg";
import FullWidthBanner from "./FullWidthBanner";
import HeaderOverBanner from "./HeaderOverBanner";
import ContentOverBanner from "./ContentOverBanner";
import "./styles/ContactSection.scss";

class ContactSection extends React.Component {
  render() {
    return (
      <section className="contact-section">
        <FullWidthBanner
          color="blue"
          backgroundImage={manWithPhoneImage}
          id="contact"
        />
        <HeaderOverBanner>
          <h3 className="header-over-banner__title">
            Our pharmacists are only a phone call away
          </h3>
        </HeaderOverBanner>
        <ContentOverBanner>
          <h2 className="content-over-banner__title">Contact Us</h2>
          <h4>E-mail</h4>
          <p itemProp="email">
            <a href="mailto:contactus@priorityhealthrx.com">
              contactus@priorityhealthrx.com
            </a>
          </p>
          <h4>Phone</h4>
          <p itemProp="telephone">
            <a
              href="tel:+18556522163"
              aria-label="Telephone number 8 5 5. 6 5 2. 2 1 6 3."
            >
              (855) 652-2163
            </a>
          </p>
          <h4>Fax</h4>
          <p itemProp="faxNumber">(855) 665-2439</p>
          <h4>Mailing Address</h4>
          <a
            href="https://www.google.com/maps/place/4625+Halder+Ln+a,+Orlando,+FL+32814/@28.5584588,-81.3311146,17z/data=!3m1!4b1!4m5!3m4!1s0x88e76538046c94ab:0xe6c5555bbeca044a!8m2!3d28.5584588!4d-81.3285397?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <address
              itemProp="address"
              itemScope
              itemType="http://schema.org/PostalAddress"
            >
              Priority Health Rx
              <br />
              <span itemProp="streetAddress">
                4625 Halder Lane Ste A
              </span>
              <br />
              <span itemProp="addressLocality">Orlando</span>,{" "}
              <span itemProp="addressRegion">FL</span>{" "}
              <span itemProp="postalCode">32814</span>
            </address>
          </a>
        </ContentOverBanner>
      </section>
    );
  }
}

export default ContactSection;
