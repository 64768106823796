import * as React from "react";
import "./styles/SiteNavigation.scss";

class SiteNavigation extends React.Component {
  render() {
    return (
      <nav className="site-navigation">
        <ul id="aria_menu" aria-labelledby="aria_menu-button" role="menu">
          <li role="none">
            <a
              href="tel:+18556522163"
              className="site-navigation__contact-number"
              role="menuitem"
              aria-label="Telephone number 8 5 5. 6 5 2. 2 1 6 3.">
              (855) 652-2163
            </a>
          </li>
          <li role="none">
            <a role="menuitem" href="#services">Services</a>
          </li>
          <li role="none">
            <a role="menuitem" href="#about">About Us</a>
          </li>
          <li role="none">
            <a role="menuitem" href="#contact">Contact</a>
          </li>
          <li role="none">
            <button
              id="aria_enroll-pop-up-button"
              className="site-navigation__enroll-button"
              onClick={this.props.handleEnrollClick}
              aria-haspopup="dialog"
              aria-controls="aria_enroll-pop-up"
              aria-expanded={this.props.popUpState}>
                Enroll
              </button>
          </li>
        </ul>
      </nav>
    );
  }
}

export default SiteNavigation;
