import * as React from "react";
import "./styles/ContentOverBanner.scss";

const ContentOverBanner = (props) =>  {
  return (
    <article className="content-over-banner">
      { props.children }
    </article>
  );
}
export default ContentOverBanner;
