import * as React from "react";
import CalendlyInlineWidget from "./CalendlyInlineWidget";
import "./styles/EnrollPopUp.scss";

class EnrollPopUp extends React.Component {
  render() {
    return (
      <section
        className="enroll-pop-up"
        aria-labelledby="aria_enroll-pop-up-button"
        role="dialog">
        <div className="enroll-pop-up__form-container">
          <button className="enroll-pop-up__close-button" onClick={this.props.handlePopUpClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18"
              height="18" viewBox="0 0 13.2 13.2">
              <defs>
                <title>Close this modal</title>
              </defs>
              <path d="M12.9,0.3c-0.4-0.4-1-0.4-1.4,0L6.6,5.2L1.7,0.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4.9,4.9l-4.9,4.9c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L6.6,8l4.9,4.9c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L8,6.6l4.9-4.9C13.3,1.3,13.3,0.7,12.9,0.3z"/>
            </svg>
          </button>
          <h2 className="enroll-pop-up__title">Let’s Get Started!</h2>
          <p className="enroll-pop-up__description">
            Pick a day and time to schedule a quick 10 minute call.
          </p>
          <CalendlyInlineWidget />
        </div>
      </section>
    );
  }
}

export default EnrollPopUp;
