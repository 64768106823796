import * as React from "react";
import pharmacyShelvesImage from "./images/pharmacy-shelves.jpg";
import FullWidthBanner from "./FullWidthBanner";
import HeaderOverBanner from "./HeaderOverBanner";
import ContentOverBanner from "./ContentOverBanner";
import "./styles/AboutSection.scss";

class AboutSection extends React.Component {
  render() {
    return (
      <section className="about-section">
        <FullWidthBanner color="purple" backgroundImage={pharmacyShelvesImage} id="about" />
        <HeaderOverBanner>
          <h3 className="header-over-banner__title">Our pharmacists are dedicated</h3>
        </HeaderOverBanner>
        <ContentOverBanner>
          <h2 className="content-over-banner__title">About Us</h2>
          <p>Priority Health Rx is dedicated to providing pharmacy services and medical supplies to the Healthcare community.</p>
          <p>We are based in Orlando, FL and are licensed nationwide throughout local and state municipalities as a community pharmacy registered with the DEA. Our DEA registration allows for Priority Health Rx to service a broad range of patients nationwide.</p>
          <p>Our pharmacists are dedicated to providing the highest level of quality and utilize the most advanced instrumentation and technology in the industry. And our pharmacists are only a phone call away &mdash; let our pharmacy come to you!</p>
        </ContentOverBanner>
      </section>
    );
  }
}

export default AboutSection;
