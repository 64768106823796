import * as React from "react";
import "./styles/CalendlyInlineWidget.scss";

class CalendlyInlineWidget extends React.Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  render(){
    return (
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/priorityhealthrx/enrollment-call" />
    );
  }
}

export default CalendlyInlineWidget;
