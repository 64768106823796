import * as React from "react";
import "./styles/HeaderOverBanner.scss";

const HeaderOverBanner = (props) =>  {
  return (
    <header className="header-over-banner">
      { props.children }
    </header>
  );
}
export default HeaderOverBanner;
