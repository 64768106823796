import * as React from "react";
import priorityHealthRxLogo from "./images/priority-health-rx-logo.png";
import SiteNavigation from "./SiteNavigation";
import "./styles/SiteHeader.scss";

class SiteHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state = { isMenuOpen: false }
  }

  handleMenuClick = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  }

  render() {
    return (
      <header className={this.state.isMenuOpen ? "site-header site-header--menu-is-open" : "site-header"}>
        <div className="site-header__left">
          <a href="/" className="site-header__logo-link">
            <img className="site-header__logo" src={priorityHealthRxLogo} alt="Priority Health Rx home"/>
          </a>
          <a
            href="tel:+18556522163"
            className="site-header__contact-number"
            aria-label="Telephone number 8 5 5. 6 5 2. 2 1 6 3.">
            (855) 652-2163
          </a>
        </div>
        <div className="site-header__right">
          <button
            id="aria_menu-button"
            className="site-header__menu-toggle"
            onClick={this.handleMenuClick}
            aria-haspopup="menu"
            aria-controls="aria_menu"
            aria-expanded={this.state.isMenuOpen ? "true" : "false"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 19.8 16.8">
            <defs>
              <title>Menu</title>
            </defs>
            <rect width="19.8" height="3.5"/>
            <rect y="6.6" width="19.8" height="3.5"/>
            <rect y="13.3" width="19.8" height="3.5"/>
            </svg>
          </button>
          <SiteNavigation
            handleEnrollClick={this.props.handleEnrollClick} popUpState={this.props.popUpState} />
        </div>
      </header>
    );
  }
}
export default SiteHeader;
