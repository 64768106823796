import * as React from "react";
import "./styles/FullWidthBanner.scss";

const FullWidthBanner = (props) =>  {
  return (
    <div
      id={props.id}
      className={`full-width-banner full-width-banner--${props.color}`}
      style={{backgroundImage: `url(${props.backgroundImage})`}}>
    </div>
  );
}
export default FullWidthBanner;
