import * as React from "react";
import "./styles/SiteFooter.scss";

const SiteFooter = () => {
  return (
    <footer className="site-footer">
      <p>
        &copy; Copyright 2013-2023. Priority Health Rx. All Rights Reserved.
      </p>
    </footer>
  );
};

export default SiteFooter;
